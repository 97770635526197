<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filtersName"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="$admin.can('oem-customers.edit')"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>

        <v-icon
          v-if="$admin.can('oem-customers.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.is_active"
            dense
            clearable
            :items="statusLists"
            item-value="id"
            item-text="name"
            :label="$t('status')"
            @change="fetchData"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.test_customer"
            dense
            :items="customerTypeLists"
            item-value="id"
            item-text="name"
            :label="
              options.product == 'fleet'
                ? $t('driver_type')
                : $t('customer_type')
            "
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-modal
            v-if="$admin.can('oem-customers.create')"
            :filter-options="{ ...options, customFilters }"
          />
          <edit-modal
            v-if="$admin.can('oem-customers.edit') && editModal"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :oem-customer="selectedRow"
          />
          <confirmation-modal
            v-if="$admin.can('oem-customers.delete')"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateModal: () => import("./dialogs/CreateOemCustomer.vue"),
    EditModal: () => import("./dialogs/EditOemCustomer.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      customFilters: {
        test_customer: "0",
      },

      statusLists: [
        {
          id: "1",
          name: "Active",
        },
        {
          id: "0",
          name: "Inactive",
        },
      ],
      customerTypeLists: [
        {
          id: "0",
          name: "Real",
        },
        {
          id: "1",
          name: "Test",
        },
      ],
      headers: [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },

        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
          // width: "15%",
        },
        {
          text: this.$t("email"),
          align: "left",
          sortable: false,
          value: "email",
          // width: "15%",
        },
        {
          text: this.$t("user_type"),
          align: "left",
          sortable: false,
          value: "type_of_user",
          // width: "10%",
        },
        {
          text: this.$t("customer_type"),
          align: "left",
          sortable: false,
          value: "customerType",
          // width: "10%",
        },

        {
          text: this.$t("phone_number"),
          align: "left",
          sortable: false,
          value: "phone_number",
          // width: "10%",
        },
        {
          text: this.$t("status"),
          align: "left",
          sortable: false,
          value: "status",
        },
        ...(this.$admin.hasAccessTo("oem-customers.delete") ||
        this.$admin.hasAccessTo("oem-customers.edit")
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "oemCustomers/getIsLoadingOemCustomers",
      meta: "oemCustomers/getOemCustomersMeta",
      list: "oemCustomers/getOemCustomersList",

      oemB2bList: "oemBusiness/getOEMB2BList",
    }),
    _headers() {
      // computed headers for showing columns to role based
      const h = this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );

      const arr = [];
      const obj = this;
      h.forEach((el) => {
        if (el.value == "customerType") {
          if (this.options.product == "fleet") {
            el.text = obj.$t("driver_type");
          } else {
            el.text = obj.$t("customer_type");
          }
        }
        arr.push(el);
      });

      return arr;
    },
    filtersName() {
      return ["search", "customer"];
    },
  },
  watch: {
    "subFilters.user_type": {
      handler: function (v) {
        if (v == "business") {
          this.$store.dispatch("oemBusiness/list", {
            tenant_id: this.options.tenant_id,
          });
        }
      },
    },

    options() {
      this.fetchData();
    },
  },
  async mounted() {
    // await this.$store.dispatch("batteryTypes/list");
    // for test customer
    if (this.$route?.query?.test_customer) {
      this.customFilters.test_customer = this.$route?.query?.test_customer;
    }
    // for status
    if (this.$route?.query?.is_active) {
      this.customFilters.is_active = this.$route?.query?.is_active;
    }
  },
  beforeDestroy() {
    const params = {
      name: "oem_customers",
      filters: {
        ...this.options,
        ...this.customFilters,
      },
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      // if (!this.options.test_customer) {
      //   this.subFilters.test_customer = "0";
      //   this.options.test_customer = "0";
      // }
      const params = {
        ...this.options,
        ...this.customFilters,
      };

      await this.$store.dispatch("oemCustomers/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    // onTestCustomerChange(v) {
    // if (v) {
    //   this.options.test_customer = v;
    // } else {
    //   this.subFilters.test_customer = "0";
    //   this.options.test_customer = "0";
    // }
    // this.fetchData();
    // },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("oemCustomers/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
